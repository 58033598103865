import { Form } from 'dataformjs';
import { navigate } from 'gatsby';
import React, { FC, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import CircularProgress from '../../../components/base/CircularProgress';
import GridSplit from '../../../components/base/GridSplit';
import AsideBody from '../../../components/Space/Aside/Body';
import spaceQuery from '../../../objects/spaces/queries/space.graphql';
import userData from '../../../objects/users/datas.json';
import { usersCreate as createMutation } from '../../../objects/users/mutations';
import SpaceType from '../../../types/Space';
import pageProps, { ResultProps } from '../../../utils/pageProps';
import requiredAuth from '../../../utils/requireAuth';

const UserCreatePage: FC<ResultProps> = ({ params: { space: spaceId } }) => {
  const formName = 'user';
  const [createMutationFunc] = useMutation(createMutation);
  const { t } = useTranslation();
  const { data, error, loading } = useQuery(spaceQuery, {
    variables: {
      id: spaceId,
    },
  });

  const [minuteur, setMinuteur] = useState<NodeJS.Timeout>();
  const [formattedAddress, setFormattedAddress] = useState<string>();

  if (loading) {
    return <CircularProgress fullPage />;
  }

  if (error) {
    return <pre>{JSON.stringify(error, null, 2)}</pre>;
  }

  const handleOnChange = (values: any) => {
    if (minuteur) {
      clearTimeout(minuteur);
    }

    const { address } = values;
    setMinuteur(
      setTimeout(() => {
        setFormattedAddress(address);
      }, 1000),
    );
  };

  const handleOnSubmit = async (values: any) => {
    const result = await toast.promise(
      createMutationFunc({
        variables: {
          ...values,
          spaceId,
        },
      }),
      {
        error: "Erreur lors de la création d'un usager",
        pending: "Création en cours d'un usager",
        success: "Création d'un usager réussie",
      },
    );

    if (!result || result.errors) {
      toast.error(result.errors);
    } else {
      const userId = result.data.usersCreate.id;
      window.history.replaceState(
        {
          spaceId,
          userId,
        },
        '',
        `/admin/spaces/update/${spaceId}/?tab=2`,
      );
      navigate(`/admin/users/update/${userId}/`);
    }
  };

  const space: SpaceType = data && (data.space as SpaceType);

  if (!space) {
    return null;
  }

  return (
    <GridSplit
      aside={{
        body: space.places && (
          <AsideBody
            places={
              formattedAddress
                ? [
                    {
                      formattedAddress,
                    },
                  ]
                : []
            }
          />
        ),
        className: 'sticky top-0 self-start',
        width: '40%',
      }}
      header={{
        back: {
          onClick: () => {
            window.history.back();
          },
          text: t(`back`),
        },
        title: t(`space.form.fields.user.create.title`),
      }}
    >
      <Form
        className="mx-6 mb-6"
        datas={userData}
        footerProps={{
          className: 'mt-12 flex justify-end',
        }}
        name={formName}
        onChange={handleOnChange}
        onSubmit={handleOnSubmit}
        params={{
          spaceId,
        }}
        submitProps={{
          label: t(`space.form.fields.user.create.submit`),
        }}
      />
    </GridSplit>
  );
};

export default requiredAuth(pageProps(UserCreatePage), {
  admin: true,
  layout: true,
});
